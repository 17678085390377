export const useAccountStore = defineStore('account', () => {
  const lastUpdated = ref(new Date())

  return {
    lastUpdated,
  }
})

// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useAccountStore, import.meta.hot))
